import React from "react";
import styled from "styled-components";
import device from "../Responsive";

const StyledTitle = styled.h2`
  font-family: "rubik";
  font-weight: 400;
  height: 100%;
  margin: 1rem;
  text-decoration: underline;
  @media ${device.mobileL} {
    font-size: 1rem;
  }

  @media ${device.tablet} {
    font-size: 1.5rem;
  }

  @media ${device.laptop} {
    font-size: 3rem;
  }

  @media ${device.desktop} {
    font-size: 5rem;
  }
`;

const StyledSubTitle = styled.h3`
  font-family: "rubik";
  font-weight: 400;
  height: 100%;
  margin: 1rem;

  @media ${device.mobileL} {
    font-size: 0.75rem;
  }

  @media ${device.tablet} {
    font-size: 1.125rem;
  }

  @media ${device.laptop} {
    font-size: 2.25rem;
  }

  @media ${device.desktop} {
    font-size: 3.75rem;
  }
`;

export const AboutGrid = styled.div`
  display: grid;
  grid-gap: 5px;
  align-items: center;
`;

export const AboutPage = () => {
  return (
    <AboutGrid>
      <span>
        <StyledTitle>What Am I Up To Right Now 🤔?</StyledTitle>
        <ul>
          <StyledSubTitle>
            * Messing around with Android Development on an open source app <a href="https://github.com/salahapp">Salah</a> (OSS
            Prayer Apps)
          </StyledSubTitle>
          <StyledSubTitle>* Working on this website 😛</StyledSubTitle>
          <StyledSubTitle>
            * Working on some CTF's (Mainly web applications)
          </StyledSubTitle>
        </ul>
      </span>

      <span>
        <StyledTitle>Where else you can find me?</StyledTitle>
        <ul>
          <StyledSubTitle>
            * Twitter: <a href="https://twitter.com/x0r">@x0r</a>
          </StyledSubTitle>
          <StyledSubTitle>
            * Linkedin:{" "}
            <a href="https://www.linkedin.com/in/-fm/">Faris Mohamed</a>
          </StyledSubTitle>
          <StyledSubTitle>
            * Github: <a href="https://github.com/faris">@faris</a>
          </StyledSubTitle>
          <StyledSubTitle>
            * Hacker News:{" "}
            <a href="https://news.ycombinator.com/user?id=fm0">@fm0</a>
          </StyledSubTitle>
          <StyledSubTitle>
            * Email: my_first_name at posteo (dot) net
          </StyledSubTitle>
        </ul>
      </span>
    </AboutGrid>
  );
};

export default AboutPage;
