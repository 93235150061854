import React from "react";
import NavBarComponent from "./Components/NavBar/NavBar";
import styled from "styled-components";
import HomePage from "./Components/Pages/HomePage";
import ResumePage from "./Components/Pages/Resume";
import AboutPage from "./Components/Pages/AboutPage";
import { Router, RouteComponentProps } from "@reach/router";

const Grid = styled.div`
  display: grid;
  grid-auto-flow: row;
`;

const RoutedHomePage = (props: RouteComponentProps) => <HomePage />;
const RoutedResumePage = (props: RouteComponentProps) => <ResumePage />;
const RoutedAboutPage = (props: RouteComponentProps) => <AboutPage />;

const App = () => {
  return (
    <>
      <NavBarComponent />
      <Grid>
        <Router>
          <RoutedHomePage path="/" />
          <RoutedResumePage path="/resume" />
          <RoutedAboutPage path="about" />
        </Router>
      </Grid>
    </>
  );
};

export default App;
