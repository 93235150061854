// @ts-nocheck

// TODO: Fix all of the type errors and get rid of the @ts-nocheck

import React from "react";
import { Document, Page } from "react-pdf";
import samplePDF from "../../resume.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { SizeMe } from "react-sizeme";

const ResumePage = () => {
  return (
    <SizeMe
      monitorHeight
      refreshRate={128}
      refreshMode={"debounce"}
      render={({ size }) => (
        <Document file={samplePDF}>
          <Page pageNumber={1} width={size.width} />
        </Document>
      )}
    />
  );
};

export default ResumePage;
