import React from "react";
import "@fontsource/rubik";
import styled from "styled-components";
import SlackMojiImage from "../SlackMoji";
import carletonImage from "../../Images/carleton.png";
import burningMac from "../../Images/computer-fire.gif";
import arrowUp from "../../Images/arrow.gif";
import device from "../Responsive";

export const CenteredItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: no-wrap;
`;
export const StyledText = styled.h2`
  font-family: "rubik";
  font-weight: 400;
  @media ${device.mobileL} {
    font-size: 1rem;
  }

  @media ${device.tablet} {
    font-size: 1.5rem;
  }

  @media ${device.laptop} {
    font-size: 3.75rem;
  }

  @media ${device.desktop} {
    font-size: 5rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.05fr 2fr 0.05fr;
  grid-gap: 5px;
`;

const WelcomeToMyWebsite = () => {
  return (
    <Grid>
      <div />
      <CenteredItem>
        <StyledText>
          Welcome to my website. I am currently a student at Carleton University{" "}
          <SlackMojiImage image={carletonImage} />, where I am a 4th year
          Computer Science (Honours) Student{" "}
          <SlackMojiImage image={burningMac} />. Feel free to check-out some of
          my other pages to see what I am up to.{" "}
          <SlackMojiImage image={arrowUp} />{" "}
        </StyledText>
      </CenteredItem>
      <div />
    </Grid>
  );
};

export default WelcomeToMyWebsite;
