import React from "react";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { Link } from "@reach/router";

const DarkModeToggle = () => {
  // TODO: Add ThemeProvider and fix styling :)

  const [isDarkMode, setDarkMode] = React.useState(false);

  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
  };

  return (
    <DarkModeSwitch
      checked={isDarkMode}
      onChange={toggleDarkMode}
      moonColor="black"
    />
  );
};

const NavBarComponent = () => (
  <Navbar expand="lg">
    <Navbar.Brand href="/" as={Link} to="/">
      Faris Mohamed
    </Navbar.Brand>
    <DarkModeToggle />
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto"></Nav>
      <Nav className="ml-auto">
        <Nav.Link href="/" as={Link} to="/">
          Home
        </Nav.Link>
        <Nav.Link href="/about" as={Link} to="/about">
          About
        </Nav.Link>
        <Nav.Link href="/resume" as={Link} to="/resume">
          Resume
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default NavBarComponent;
