import React from "react";
import profilePic from "../../Images/test.png"; // with import
import waveGif from "../../Images/waving.gif"; // with import
import "@fontsource/rubik";
import styled from "styled-components";
import device from "../Responsive";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5vw;
  align-items: center;
`;

export const ProfilePic = styled.img`
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
  align-self: center;
  justify-self: center;
`;

export const CenteredItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: no-wrap;
`;
export const StyledHeader = styled.h1`
font-family: "rubik";
font-weight: 700;
height: 100%
align-items: center;
@media ${device.mobileL} { 
    font-size: 2rem;
  }

@media ${device.tablet} {
    font-size: 3rem;
  }

@media ${device.laptop} {
    font-size: 7.5rem;
  }

@media ${device.desktop} {
    font-size: 10rem;
  }
`;

const BiggerSlackMoji = styled.img`
  @media ${device.mobileL} {
    width: 2rem;
    height: 2rem;
  }

  @media ${device.tablet} {
    width: 3rem;
    height: 3rem;
  }

  @media ${device.laptop} {
    width: 7.5rem;
    height: 7.5rem;
  }

  @media ${device.desktop} {
    width: 10rem;
    height: 10rem;
  }
`;

const ProfilePictureComponent = () => {
  return <ProfilePic src={profilePic} />;
};

const AboutMe = () => {
  return (
    <StyledHeader>
      Hello I am Faris <BiggerSlackMoji src={waveGif} />
    </StyledHeader>
  );
};

const HiMyNameIs = () => {
  return (
    <Grid>
      <ProfilePictureComponent></ProfilePictureComponent>
      <AboutMe></AboutMe>
    </Grid>
  );
};

const Introduction = () => {
  return <HiMyNameIs />;
};

export default Introduction;
