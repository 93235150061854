import React from "react";
import Introduction from "../Introduction/Introduction";
import WelcomeToMyWebsite from "../TextBox/TextBox";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 5vh;
`;

const HomePage = () => {
  return (
    <Grid>
      <Introduction />
      <WelcomeToMyWebsite />
    </Grid>
  );
};

export default HomePage;
