import React from "react";
import styled from "styled-components";
import device from "./Responsive";

type SlackMoji = {
  image: string;
};

const GifPic = styled.img`
  @media ${device.mobileL} {
    height: 0.75rem;
    width: 0.75rem;
  }

  @media ${device.tablet} {
    height: 1.125rem;
    width: 1.125rem;
  }

  @media ${device.laptop} {
    height: 2.8125rem;
    width: 2.8125rem;
  }

  @media ${device.desktop} {
    height: 3.75 rem;
    width: 3.75 rem;
  }
`;

const SlackMojiImage = ({ image }: SlackMoji) => {
  return <GifPic src={image} />;
};

export default SlackMojiImage;
